<template>
  <CRow class="mb-5">
    <CCol lg="4" xl="3">
      <TAside class="card">
        <CListGroup>
          <CListGroupItem>
            <CRow>
              <CCol col="12" lg="12" xl="6">
                <TMessage content="From date" bold class="mb-2" />
                <TInputDateTime
                  :value.sync="filter.start_day"
                  timeline="start"
                  placeholder="Start date"
                  class="mb-3"
                  :key="startDateKey"
                />
              </CCol>
              <CCol col="12" lg="12" xl="6">
                <TMessage content="To date" bold class="mb-2" />
                <TInputDateTime
                  :value.sync="filter.end_day"
                  timeline="end"
                  placeholder="End date"
                  class="mb-3"
                  :key="endDateKey"
                />
              </CCol>
              <CCol col="12">
                <SSelectCurrency
                  :value.sync="filter.currency_id"
                  label="Currency"
                  :prepend="[]"
                />
              </CCol>
            </CRow>
            <hr />
            <CRow>
              <CCol col="12" class="d-flex mt-2">
                <TButton
                  class="w-100"
                  content="Filter"
                  @click="onFilter"
                  :disabled="loading || !filter.end_day || !filter.start_day"
                />
                <TButtonClear
                  @click="clearFilter"
                  variant="outline"
                  class="ml-1"
                  size=""
                />
              </CCol>
            </CRow>
          </CListGroupItem>
          <CListGroupItem v-if="loading">
            <TSpinner :loading="loading" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(object, index) in list"
            :key="`${object.id}-${index}`"
            :color="selected.id == object.id ? 'primary' : 'secondary'"
            :to="
              !loading
                ? _.getAppendRouteQuery($route, {
                    id: object.id,
                  })
                : ''
            "
          >
            <div class="d-flex">
              <TMessage :content="object.name" noTranslate bold />
              <TMessageMoney
                :amount="
                  object.accounting_period
                    ? object.accounting_period.during_balance
                    : 0
                "
                class="ml-auto"
                :currency="object.currency_id"
              />
            </div>
          </CListGroupItem>
          <CListGroupItem v-if="!list.length && !loading">
            <TMessageNotFound />
          </CListGroupItem>
          <SButtonLoadmore
            v-if="!loading && list.length"
            store="accounting.statistics_cards_period"
            class="mt-2 mb-3"
          />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="8" xl="9">
      <CCard v-if="!lodash.isEmpty(detail) && selected.id">
        <CCardBody>
          <div class="d-flex mb-2">
            <TMessage :content="detail.name" noTranslate bold />
            <SMessageCurrency :id="detail.currency_id" class="ml-1" />
            <TButton
              content="Export excel"
              icon="cid-cloud-download"
              size="sm"
              @click="exportExcel"
              class="ml-auto"
              :disabled="export_loading"
            />
          </div>
          <CRow>
            <CCol col="12" md="4">
              <TWidgetSimple
                title="Opening balance"
                color="primary"
                alignment="left"
                class="mb-0"
              >
                <template #content>
                  <TMessageMoney
                    :amount="
                      detail.accounting_period
                        ? detail.accounting_period.opening_balance
                        : 0
                    "
                    :currency="detail.currency_id"
                    color="primary"
                    class="h3"
                  />
                </template>
              </TWidgetSimple>
            </CCol>
            <CCol col="12" md="4">
              <TWidgetSimple
                title="During balance"
                color="primary"
                alignment="left"
                class="mb-0"
              >
                <template #content>
                  <TMessageMoney
                    :amount="
                      detail.accounting_period
                        ? detail.accounting_period.during_balance
                        : 0
                    "
                    :currency="detail.currency_id"
                    color="primary"
                    class="h3"
                  />
                </template>
              </TWidgetSimple>
            </CCol>
            <CCol col="12" md="4">
              <TWidgetSimple
                title="Closing balance"
                color="primary"
                alignment="left"
                class="mb-0"
              >
                <template #content>
                  <TMessageMoney
                    :amount="
                      detail.accounting_period
                        ? detail.accounting_period.closing_balance
                        : 0
                    "
                    :currency="detail.currency_id"
                    color="primary"
                    class="h3"
                  />
                </template>
              </TWidgetSimple>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>

      <TableTransactions
        v-if="!lodash.isEmpty(detail) && showTransation && selected.id"
        :key="transactionTBkey"
        :showVoucherDateFilter="false"
        store="accounting.transactions"
        class="mb-4"
        showReference
        :default_filter="{
          'filter[card_id]': selected.id,
          'filter[currency_id]': filterSelected.currency_id,
          'filter_between[voucher_date]': `${filterSelected.start_day},${filterSelected.end_day}`,
          'filter[debit_or_credit]': detail.account_id,
          sort: '-id',
          append: 'object,wallet,references',
          include: 'preparedBy',
        }"
      />
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";
import TableTransactions from "../../components/TableTransactions.vue";
import mixin from "./mixin";

export default {
  components: { TableTransactions },
  mixins: [mixin],
  data() {
    return {
      filter: {
        currency_id: "",
        start_day: this.lodash.toTimeStamp(Date.now(), "start"),
        end_day: this.lodash.toTimeStamp(Date.now(), "end"),
      },
      store: "accounting.statistics_cards_period",
      keySelected: "id",
    };
  },
  mounted() {
    this.lodash.appendRouteQuery(this.$router, {
      query: this.lodash.omit(this.$route.query, "id"),
    });
  },
  watch: {
    "queryRoute.id": {
      immediate: true,
      deep: true,
      handler(newId, oldId) {
        if (oldId != newId) {
          this.showTransation = true;
          const objSelected = this.list.find((x) => x.id == newId);
          if (objSelected) {
            this.$store.commit(
              "accounting.statistics_cards_period.select",
              objSelected.id
            );
            this.transactionTBkey = this.lodash.resetKey("transactionTBkey");
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      export_loading: "helper.exports.loading",
    }),
  },
  methods: {
    appendRoute() {
      this.lodash.appendRouteQuery(this.$router, {
        query: {
          ...this.$route.query,
          currency_id: this.filter?.currency_id,
          start_day: this.filter?.start_day.toString(),
          end_day: this.filter?.end_day.toString(),
        },
      });
    },
    onFilter() {
      if (this.filter.currency_id) this.showTransation = true;
      this.appendRoute();
      this.fetchPeriod(this.filter);
      this.filterSelected = this.filter;
    },
    clearFilter() {
      this.lodash.appendRouteQuery(this.$router, {
        query: {
          ...this.lodash.pick(this.$route.query, ["id"]),
        },
      });
      this.filter = {
        currency_id: "",
        start_day: this.lodash.toTimeStamp(Date.now(), "start"),
        end_day: this.lodash.toTimeStamp(Date.now(), "end"),
      };
      this.radioKey = this.lodash.resetKey("radioKey");
    },
    fetchPeriod(filter) {
      this.$store
        .dispatch(
          this.store + ".apply-query",
          this.lodash.pickBy({
            start_day: filter.start_day || "0",
            end_day: filter.end_day,
            currency_id: filter.currency_id,
          })
        )
        .then(() => {
          const objSelected = this.list.find((x) => x.id == this.detail?.id);
          if (objSelected) {
            this.transactionTBkey = this.lodash.resetKey("transactionTBkey");
            this.$store.commit(this.store + ".detail.merge", objSelected);
          }
        });
    },
  },
};
</script>
